<template>
  <div class="absolute-full no-pointer-events overflow-visible">
    <swiper-container class="fit" init="false" ref="swiper" style="z-index: 0">
      <swiper-slide v-for="(image, index) in images" :key="image.src">
        <q-img
          alt="background aura"
          :src="image.src"
          class="fit"
          no-spinner
          ref="imageRefs"
          :style="imageStyles[index]"
        />
      </swiper-slide>
    </swiper-container>
  </div>
</template>

<script setup>
import { onMounted, nextTick, ref, watch } from "vue";
import { useMotion } from "@vueuse/motion";
import { storeToRefs } from "pinia";
import { debounce } from "quasar";
import { useAuraStore } from "src/stores/aura";

defineOptions({ name: "ImageAuraClone" });

const swiperParams = {
  effect: "fade",
  speed: 2000,
  on: {
    slideChangeTransitionEnd() {
      debounceRemoveOldImage();
    },
  },
};

const auraStore = useAuraStore();
const { auraClone } = storeToRefs(auraStore);
const images = ref([{ src: auraClone.value }]);
const swiper = ref(null);
const imageRefs = ref([]);
const imageStyles = ref([]);

const createMotionInstance = (ref) => {
  return useMotion(ref, {
    leave: {
      opacity: 0,
      transition: {
        duration: 8000,
        onComplete: () => {
          removeImage();
        },
      },
    },
  });
};

const applyMotion = (index, state) => {
  const ref = imageRefs.value[index];
  if (ref) {
    const motionInstance = createMotionInstance(ref);
    motionInstance.apply(state);
  }
};

const removeImage = () => {
  if (images.value.length > 1) {
    images.value.shift();
  }
};

const debounceRemoveOldImage = debounce(() => {
  const oldIndex = images.value.length - 3;
  if (oldIndex >= 0) {
    applyMotion(oldIndex, "leave");
  }
}, 400);

watch(auraClone, (newAuraClone) => {
  if (newAuraClone) {
    images.value.push({ src: newAuraClone });
  }
  if (!swiper.value) return;
  nextTick(() => {
    swiper.value.swiper.slideNext();
  });
});

onMounted(() => {
  Object.assign(swiper.value, swiperParams);
  nextTick(() => {
    if (swiper.value) swiper.value.initialize();
  });
});
</script>
